// vertexShader.js
var vertexShader = `
attribute vec2 coordinates;
varying vec2 texCoord;
void main(void){
	texCoord = (coordinates/2.0 + 0.5);
	gl_Position = vec4(coordinates, 1.0, 1.0);
}`;

// fragmentShader.js
var fragmentShader = `
precision mediump float;
uniform sampler2D u_image;
varying vec2 texCoord;
uniform vec2 onePixel;
uniform bool doStep;
vec2 getCoords(vec2 coord, vec2 offset){
	return mod(coord + onePixel * offset, 1.0);
}
float r = 0.0;
float sum = 0.0;
void main(void){
	if(doStep){
		float r = texture2D(u_image, texCoord).r;
		float sum = 0.0;
		sum += texture2D(u_image, getCoords(texCoord, vec2(-1.0, -1.0))).r;
		sum += texture2D(u_image, getCoords(texCoord, vec2(0.0, -1.0))).r;
		sum += texture2D(u_image, getCoords(texCoord, vec2(1.0, -1.0))).r;
		sum += texture2D(u_image, getCoords(texCoord, vec2(-1.0, 1.0))).r;
		sum += texture2D(u_image, getCoords(texCoord, vec2(0.0, 1.0))).r;
		sum += texture2D(u_image, getCoords(texCoord, vec2(1.0, 1.0))).r;
		sum += texture2D(u_image, getCoords(texCoord, vec2(-1.0, 0.0))).r;
		sum += texture2D(u_image, getCoords(texCoord, vec2(1.0, 0.0))).r;
		if(r != 0.0 && (sum < 2.0 || sum > 3.0)){
			r = 0.0;
		} else if(r == 0.0 && sum == 3.0){
			r = 1.0;
		}
		gl_FragColor = vec4(r, r, r, r);
	} else {
		gl_FragColor = texture2D(u_image, texCoord).rgba;
	}
}`;

function Renderer(canvas, world) {
  var gl =
    canvas.getContext("webgl") || canvas.getContext("experimental-webgl");

  var shaderProgram, size;
  var onePixelAttr, doStepAttr;
  var fba, fbb, txa, txb;
  var worldTexture;

  var lastHeight = canvas.height;
  var lastWidth = canvas.width;

  var superfast = false;

  init();

  function init() {
    var vertShader = gl.createShader(gl.VERTEX_SHADER);

    // Attach vertex shader source code
    gl.shaderSource(vertShader, vertexShader);

    // Compile the vertex shader
    gl.compileShader(vertShader);

    // Create fragment shader object
    var fragShader = gl.createShader(gl.FRAGMENT_SHADER);

    // Attach fragment shader source code
    gl.shaderSource(fragShader, fragmentShader);

    // Compile the fragmentt shader
    gl.compileShader(fragShader);

    // Create a shader program object to store
    // the combined shader program
    shaderProgram = gl.createProgram();

    // Attach a vertex shader
    gl.attachShader(shaderProgram, vertShader);

    // Attach a fragment shader
    gl.attachShader(shaderProgram, fragShader);

    // Link both programs
    gl.linkProgram(shaderProgram);

    // Use the combined shader program object
    gl.useProgram(shaderProgram);

    if (gl.getShaderInfoLog(vertShader)) {
      console.warn(gl.getShaderInfoLog(vertShader));
    }
    if (gl.getShaderInfoLog(fragShader)) {
      console.warn(gl.getShaderInfoLog(fragShader));
    }
    if (gl.getProgramInfoLog(shaderProgram)) {
      console.warn(gl.getProgramInfoLog(shaderProgram));
    }

    vertexBuffer = gl.createBuffer();

    /*==========Defining and storing the geometry=======*/

    var vertices = [
      -1.0, -1.0, 1.0, -1.0, -1.0, 1.0, -1.0, 1.0, 1.0, -1.0, 1.0, 1.0,
    ];

    size = ~~(vertices.length / 2);
    gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(vertices), gl.STATIC_DRAW);
    gl.bindBuffer(gl.ARRAY_BUFFER, null);
    gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);

    // Get the attribute location
    var coord = gl.getAttribLocation(shaderProgram, "coordinates");

    // Point an attribute to the currently bound VBO
    gl.vertexAttribPointer(coord, 2, gl.FLOAT, false, 0, 0);

    // Enable the attribute
    gl.enableVertexAttribArray(coord);

    onePixelAttr = gl.getUniformLocation(shaderProgram, "onePixel");
    doStepAttr = gl.getUniformLocation(shaderProgram, "doStep");

    worldTexture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, worldTexture);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      lastWidth,
      lastHeight,
      0,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      world
    );

    // texture and framebuffer

    txa = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, txa);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      lastWidth,
      lastHeight,
      0,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      null
    );

    fba = gl.createFramebuffer();
    gl.bindFramebuffer(gl.FRAMEBUFFER, fba);

    gl.framebufferTexture2D(
      gl.FRAMEBUFFER,
      gl.COLOR_ATTACHMENT0,
      gl.TEXTURE_2D,
      txa,
      0
    );

    // texture and framebuffer

    txb = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, txb);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      lastWidth,
      lastHeight,
      0,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      null
    );

    fbb = gl.createFramebuffer();
    gl.bindFramebuffer(gl.FRAMEBUFFER, fbb);

    gl.framebufferTexture2D(
      gl.FRAMEBUFFER,
      gl.COLOR_ATTACHMENT0,
      gl.TEXTURE_2D,
      txb,
      0
    );
  }

  function render() {
    gl.uniform2f(onePixelAttr, 1 / lastWidth, 1 / lastHeight);
    gl.uniform1f(doStepAttr, true);
    gl.bindTexture(gl.TEXTURE_2D, worldTexture);
    renderInternally(false);
  }

  function renderInternally(mode) {

    gl.uniform1f(doStepAttr, true);

    if (mode) {
      gl.bindFramebuffer(gl.FRAMEBUFFER, fbb);
      gl.drawArrays(gl.TRIANGLES, 0, size);
      gl.bindTexture(gl.TEXTURE_2D, txb);
    } else {
      gl.bindFramebuffer(gl.FRAMEBUFFER, fba);
      gl.drawArrays(gl.TRIANGLES, 0, size);
      gl.bindTexture(gl.TEXTURE_2D, txa);
    }

    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    gl.uniform1f(doStepAttr, false);
    gl.drawArrays(gl.TRIANGLES, 0, size);

    window.requestAnimationFrame(() => {
      if (window.runGoL) {
        renderInternally(!mode)
      } else {
        window.restart = ()=>{
          window.runGoL = true;
          renderInternally(!mode)
        }
      }
    });
  }

  return {
    render: render,
  };
}

(() => {
  var webglife = new Webglife();

  function Webglife() {
    var canvas = document.getElementById("game-of-life");

    function scale() {
      canvas.width = document.documentElement.clientWidth;
      canvas.height = document.documentElement.clientHeight;
    }

    window.onresize = scale;
    scale();

    var width = canvas.width;
    var height = canvas.height;
    var cells = new Uint8Array(height * width * 4);

    for (var i = 0; i < cells.length; i += 4) {
      cells[i] = Math.round(Math.random() / 0.65) * 255;
    }

    window.runGoL = true;

    window.renderer = new Renderer(canvas, cells);
    renderer.render();
  }
})();
